import Utility from "../../../shared/utility";
import AddFavourite from "../../utility/js/addFav";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
export default {
  data() {
    return {
      objectNameRule: [(v) => !!v || "Role Name is required"],
      shortDescriptionRule: [(v) => (v || "").length <= 500 || "Description must be 500 characters or less"],
      roleData: [],
      roleIdData: [],
      rolemenuData: [],
      actionList: [],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      selection: [],
      roleDetails: [],
      modaldialog: false,
      roleName: "",
      favouriteList: [],
      showAdd: false,
      description: "",
      installerName: "",
      totalRecords:0,
      deleteRoleId: 0,
      activeRoleId: 0,
      allowedEdit: false,
      deleteRoleDialog: false,
      addModalDialog: false,
      deleteShow: false,
      isDisabled: true,
      status: true,
      statusShow: false,
      disableCheckBox: true,
      selectionType: "selection paraent",
      isSelected: false,
      selected: false,
      backSlash: true,
      roleUsed: 0,
      searchValue: "",
      headers: [
        {
          text: "Role Name",
          align: "start",
          value: "RoleName",
          class: "primary customwhite--text",
        },
        {
          text: "Description",
          value: "Description",
          class: "primary customwhite--text",
        },
        {
          text: "Installer Name",
          value: "InstallerName",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "CreatedDate",
          class: "primary customwhite--text",
        },
        { text: "Status", value: "Status", class: "primary customwhite--text" },
        {
          text: "Actions",
          value: "action",
          class: "primary customwhite--text",
        },
      ],
      roleHeaders: [
        {
          text: "System Name",
          value: "name",
        },
        { text: "Friendly Name", value: "name" },
      ],
      sortBy: "RoleName",
      sortDesc: false,
      selectedItems: [],
      selectedValues: [],
      selectedRoleId: 0,
      page: 1,
      perPage: 9,
      pages: [],
      roleList: [],
      isActive: true,
      refresh: true,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    EncryptUtility.localStorageRemoveItem("EditPageDetails");
    this.getRoleList();
    if (EncryptUtility.localStorageDecrypt("searchStatus") !== null && EncryptUtility.localStorageDecrypt("searchStatus") !== undefined) {
      this.searchValue = EncryptUtility.localStorageDecrypt("searchStatus");
      this.isActive = this.searchValue == "true" ? true : false;
    }
    if (EncryptUtility.localStorageDecrypt("searchRole") !== null && EncryptUtility.localStorageDecrypt("searchRole") !== undefined) {
      this.roleList = EncryptUtility.localStorageDecrypt("searchRole");
    }
  },
  methods: {
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset the screen
    resetFunction() {
      this.isActive = true;
      this.roleList = "";
      EncryptUtility.localStorageRemoveItem("searchStatus");
      EncryptUtility.localStorageRemoveItem("searchRole");
      this.getRoleList();
    },
    //Allow to Edit
    allowEdit() {
      this.allowedEdit = true;
      this.isDisabled = false;
    },
    //Get Role List
    getRoleList() {
      this.roleData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("/ap/role_list?UserId=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                CreatedDate: Utility.convertESTToLocal(obj.CreatedDate),
                UpdatedDate: Utility.convertESTToLocal(obj.UpdatedDate),
              };
            });
            this.roleDataOrg = newArr.filter((obj) => obj.RoleName !== "" && obj.RoleName !== undefined);
            this.searchStatus();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Edit Role
    editItem(item) {
      this.selectedRoleId = item.RoleId;
      this.roleName = item.RoleName;
      this.description = item.Description ? item.Description : null;
      this.selectedInstaller = item.InstallId;
      this.status = item.RoleId;
      this.roleUsed = item.isDelete;
      let editDetails = {
        roleId: item.RoleId,
        roleName: item.RoleName,
        description: this.description,
        selectedInstaller: item.InstallId,
        status: item.Status,
        editRole: this.actionSetter("EditRole"),
        deleteRole: this.actionSetter("DeleteRole"),
        roleUsed: this.roleUsed,
        CreatedBy: item.CreatedBy,
        CreatedDate: item.CreatedDate,
        UpdatedBy: item.UpdatedBy,
        UpdatedDate: item.UpdatedDate,
      };
      EncryptUtility.localStorageEncrypt("EditPageDetails", JSON.stringify(editDetails));
      this.$router.push("/app-role-edit");
    },
    //Get Active Role Item
    activeItem(item) {
      this.activeRoleId = item.RoleId;
    },
    //Open delete modal popup
    allowDelete() {
      this.deleteRoleDialog = true;
    },
    //Role modal Pop up
    onNewRoleClick() {
      this.$router.push(`/app-role-add`);
    },
    //Close dialog popup
    closeDialog() {
      this.modaldialog = false;
    },
    //Status based search event
    searchStatus() {
      this.searchValue = this.isActive.toString();
      EncryptUtility.localStorageEncrypt("searchStatus", this.searchValue);
      if (EncryptUtility.localStorageDecrypt("searchStatus") !== null && EncryptUtility.localStorageDecrypt("searchStatus") !== undefined) {
        this.searchValue = EncryptUtility.localStorageDecrypt("searchStatus");
        this.isActive = this.searchValue == "true" ? true : false;
      }
      let status = this.isActive == true ? "Active" : "Inactive";
      let data = this.roleDataOrg;
      this.roleData = data.filter((obj) => obj.Status === status);
      this.totalRecords=this.roleData?.length
    },
    searchRole() {
      this.roleList=this.roleList?.toUpperCase()
      EncryptUtility.localStorageEncrypt("searchRole", this.roleList);
    },
  },
  components: {
    breadcrumbComp,
  },
};
